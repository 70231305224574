import React from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql } from 'gatsby';

import Page from '../layout/Page';
import Container from '../layout/Container';
import Hero from '../components/hero/Hero';
import Map from '../components/map/Map';
import Cta from '../components/cta/Cta';

const Distributors = ({ data, location }) => {
  const { prismicDistributors } = data;
  if (!prismicDistributors) {
    if (typeof window !== 'undefined') {
      navigate('/');
    }
    console.error('Page has not been translated.');
    return null;
  }

  const { data: prismicData } = prismicDistributors;
  const {
    page_title,
    page_description,
    page_keywords,
    page_image,
    hero_title,
    hero_subtitle,
    hero_button,
    hero_image,
    locations,
    cta_title,
    cta_subtitle,
    cta_button
  } = prismicData;

  return (
    <Page
      seo={{
        page_title,
        page_description,
        page_keywords,
        page_image,
        pathname: location.pathname
      }}
    >
      <Container size={50} isCenter>
        <Hero
          title={hero_title}
          subtitle={hero_subtitle}
          image={hero_image}
          button={hero_button[0]}
        />
      </Container>
      <Map
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBh39AOqadOxJkDso_4uoonyF8sUVMX3mQ"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `50vh` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        locations={locations}
      />

      <Container background="#1976d2">
        <Cta title={cta_title} subtitle={cta_subtitle} button={cta_button[0]} />
      </Container>
    </Page>
  );
};

Distributors.propTypes = {
  data: PropTypes.shape({
    prismicDistributors: PropTypes.shape({
      data: PropTypes.shape().isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Distributors;

export const query = graphql`
  query distributorsQuery($locale: String!) {
    prismicDistributors(lang: { eq: $locale }) {
      data {
        page_title
        page_description
        page_keywords
        page_image {
          alt
          copyright
          url
        }
        hero_title
        hero_subtitle
        hero_button {
          title
          to
        }
        hero_image {
          alt
          copyright
          url
        }
        locations {
          info
          lat
          lng
        }
        cta_title
        cta_subtitle
        cta_button {
          title
          to
        }
      }
    }
  }
`;
